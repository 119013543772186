import React, { useState } from 'react'
import styled from 'styled-components'
import { ApolloProvider } from 'react-apollo'
import { client } from './apollo/client'
import { Route, Switch, BrowserRouter, Redirect } from 'react-router-dom'
import GlobalPage from './pages/GlobalPage'
import TokenPage from './pages/TokenPage'
import PairPage from './pages/PairPage'
import { useGlobalData, useGlobalChartData } from './contexts/GlobalData'
import { isAddress } from './utils'
import AccountPage from './pages/AccountPage'
import AllTokensPage from './pages/AllTokensPage'
import AllPairsPage from './pages/AllPairsPage'
import PinnedData from './components/PinnedData'

import SideNav from './components/SideNav'
import AccountLookup from './pages/AccountLookup'
import { OVERVIEW_TOKEN_BLACKLIST, PAIR_BLACKLIST } from './constants'
import LocalLoader from './components/LocalLoader'
import { useLatestBlocks } from './contexts/Application'
import { HashRouter } from 'react-router-dom'

const AppWrapper = styled.div`
  position: relative;
  width: 100%;
  // display: flex;
`
const ContentWrapper = styled.div`
  display: grid;
  // 220px 1fr 64px
  // 64px 1fr 1419px
  // 1437px 1fr 187px
  grid-template-columns: ${({ open }) => (open ? '220px 1fr 200px' : '64px 1fr 268px')};

  // display: flex;
  // flex-direction: row-reverse;

  @media screen and (max-width: 1400px) {
    display: flex;
    // grid-template-columns: 913px 1fr;
    // display: flex;
    // width: 94%;
  }

  @media screen and (max-width: 1080px) {
    max-width: 100vw;
    overflow: hidden;
    display: flex;
    // width: 100% !important;
    flex-direction: column-reverse;
    // grid-template-columns: 1fr;
    // max-width: 100vw;
    // overflow: hidden;
    // grid-gap: 0;
  }
`

const Right = styled.div`
  // position: fixed;
  position: relative;
  right: 0;
  bottom: 0rem;
  z-index: 99;
  grid-row-start: 1;

  width: ${({ open }) => (open ? '220px' : '64px')};
  // height: ${({ open }) => (open ? 'fit-content' : '64px')};
  height: ${({ open }) => (open ? 'auto' : 'auto')};
  overflow: auto;
  background-color: ${({ theme }) => theme.bg1};
  @media screen and (max-width: 1400px) {
    display: none !important;
  }
`

const Center = styled.div`
  // height: 100%;
  // grid-column: 1;
  // grid-row: 1;
  z-index: 9999;
  transition: width 0.25s ease;
  background-color: ${({ theme }) => theme.onlyLight};
`

const WarningWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const WarningBanner = styled.div`
  background-color: #ff6871;
  padding: 1.5rem;
  color: white;
  width: 100%;
  text-align: center;
  font-weight: 500;
`

/**
 * Wrap the component with the header and sidebar pinned tab
 */
const LayoutWrapper = ({ children, savedOpen, setSavedOpen }) => {
  return (
    <>
      <ContentWrapper open={savedOpen}>
        <Center id="center">{children}</Center>
        <Right open={savedOpen}>
          <PinnedData open={savedOpen} setSavedOpen={setSavedOpen} />
        </Right>
        <SideNav />
      </ContentWrapper>
    </>
  )
}

const BLOCK_DIFFERENCE_THRESHOLD = 30

function App() {
  const [savedOpen, setSavedOpen] = useState(false)

  const globalData = useGlobalData()
  const globalChartData = useGlobalChartData()
  const [latestBlock, headBlock] = useLatestBlocks()

  // show warning
  const showWarning = headBlock && latestBlock ? headBlock - latestBlock > BLOCK_DIFFERENCE_THRESHOLD : false
  console.log(latestBlock, 'Latest Block')
  console.log(globalData, 'Global Data')
  console.log(globalChartData, 'Global Chart Data')

  return (
    <ApolloProvider client={client}>
      <AppWrapper>
        {showWarning && (
          <WarningWrapper>
            <WarningBanner>
              {`Warning: The data on this site has only synced to Ethereum block ${latestBlock} (out of ${headBlock}). Please check back soon.`}
            </WarningBanner>
          </WarningWrapper>
        )}
        {latestBlock &&
        globalData &&
        globalChartData  ? (
          <HashRouter>
            
              <Switch>
                <Route
                  exacts
                  strict
                  path="/token/:tokenAddress"
                  render={({ match }) => {
                    if (OVERVIEW_TOKEN_BLACKLIST.includes(match.params.tokenAddress.toLowerCase())) {
                      return <Redirect to="/home" />
                    }
                    if (isAddress(match.params.tokenAddress.toLowerCase())) {
                      return (
                        <LayoutWrapper savedOpen={savedOpen} setSavedOpen={setSavedOpen}>
                          <TokenPage address={match.params.tokenAddress.toLowerCase()} />
                        </LayoutWrapper>
                      )
                    } else {
                      return <Redirect to="/home" />
                    }
                  }}
                />
                <Route
                  exacts
                  strict
                  path="/pair/:pairAddress"
                  render={({ match }) => {
                    if (PAIR_BLACKLIST.includes(match.params.pairAddress.toLowerCase())) {
                      return <Redirect to="/home" />
                    }
                    if (isAddress(match.params.pairAddress.toLowerCase())) {
                      return (
                        <LayoutWrapper savedOpen={savedOpen} setSavedOpen={setSavedOpen}>
                          <PairPage pairAddress={match.params.pairAddress.toLowerCase()} />
                        </LayoutWrapper>
                      )
                    } else {
                      return <Redirect to="/home" />
                    }
                  }}
                />
                <Route
                  exacts
                  strict
                  path="/account/:accountAddress"
                  render={({ match }) => {
                    if (isAddress(match.params.accountAddress.toLowerCase())) {
                      return (
                        <LayoutWrapper savedOpen={savedOpen} setSavedOpen={setSavedOpen}>
                          <AccountPage account={match.params.accountAddress.toLowerCase()} />
                        </LayoutWrapper>
                      )
                    } else {
                      return <Redirect to="/home" />
                    }
                  }}
                />

                <Route path="/home">
                  <LayoutWrapper savedOpen={savedOpen} setSavedOpen={setSavedOpen}>
                    <GlobalPage />
                  </LayoutWrapper>
                </Route>

                <Route path="/tokens">
                  <LayoutWrapper savedOpen={savedOpen} setSavedOpen={setSavedOpen}>
                    <AllTokensPage />
                  </LayoutWrapper>
                </Route>

                <Route path="/pairs">
                  <LayoutWrapper savedOpen={savedOpen} setSavedOpen={setSavedOpen}>
                    <AllPairsPage />
                  </LayoutWrapper>
                </Route>

                <Route path="/accounts">
                  <LayoutWrapper savedOpen={savedOpen} setSavedOpen={setSavedOpen}>
                    <AccountLookup />
                  </LayoutWrapper>
                </Route>
                <Redirect to="/home" />
              </Switch>
          </HashRouter>
        ) : (
          <LocalLoader fill="true" />
        )}
      </AppWrapper>
    </ApolloProvider>
  )
}

export default App
